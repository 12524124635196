import { apiV4 } from '@/api'
import type { Organization } from '@/modules/organization/types/model.ts'

const Statuses = [
  { value: 0, name: 'PENDING' },
  { value: 1, name: 'SUCCESSFUL' },
  { value: 2, name: 'FAILED' },
] as const

type ResponseStatus = (typeof Statuses)[number]

type FailureCode =
  | 'PAYER_LIMIT_REACHED'
  | 'PAYER_NOT_FOUND'
  | 'PAYMENT_NOT_APPROVED'
  | 'INSUFFICIENT_BALANCE'
  | 'TRANSACTION_ALREADY_IN_PROCESS'
  | 'OTHER_ERROR'
  | 'CORRESPONDENT_TEMPORARILY_UNAVAILABLE'

export type TransactionResponse = {
  id: 0
  total: `${number}`
  currency: string
  creation_date: string
  transaction_type: unknown
  amount: `${number}`
  fee_percent: `${number}`
  fee_amount: `${number}`
  status: ResponseStatus
  un_transaction_id: string
  failure_reason: string
  failure_code: FailureCode
  organization: Organization
}

export type Response = {
  count: number
  next: string | null
  previous: string | null
  results: TransactionResponse[]
}

export type CreateMobilePaymentMethodBody = {
  name?: string
  phone: string
  is_default?: boolean
  payment_provider_id: number
}

export default {
  fetchTransactionsById: (paymentMethodId: number) =>
    apiV4.get<Response>(`payments/transactions/?mobile_id=${paymentMethodId}&limit=500`), // TODO: add lazy loading
  fetchPaymentMethodsByOrgId: (id: number) => apiV4.get(`organizations/${id}/payments/mobile/`),
  // TODO: Limit to 1 for now, as we only need to get first payment method
  fetchPaymentsMethods: () => apiV4.get('payments/wallet/mobile/?limit=1'),
  deletePaymentMethodById: (id: number) => apiV4.delete(`payments/wallet/mobile/${id}/`),
  createMobilePaymentMethodByOrgId: (id: number, body: CreateMobilePaymentMethodBody) =>
    apiV4.post(`organizations/${id}/payments/mobile/`, body),
}
