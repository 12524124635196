<script setup lang="ts">
import { DialogBtn } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { useModal } from '@/composables'
import { I18nT } from 'vue-i18n'

const props = defineProps({
  confirm: Function,
  isDeleting: Boolean,
})

const modal = useModal()

function handleConfirm() {
  props.confirm?.()
}
</script>

<template>
  <ModalCard
    tag="form"
    :with-mobile-header-style="false"
    stick-to-bottom
    :heading="$t('Delete card')"
    icon="trash"
    icon-color="red"
    @submit.prevent="handleConfirm"
  >
    <template #description>
      <I18nT keypath="onceYouTapDeleteCardWillBeDeletedPermanently">
        <template #action>
          <strong>{{ $t('Delete card') }}</strong>
        </template>
      </I18nT>
    </template>
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">{{ $t('buttons.cancel') }}</DialogBtn>
      <DialogBtn type="submit" danger :loading="isDeleting">
        {{ $t('Delete card') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>
